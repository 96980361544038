import React from "react";

import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useLocation } from "@reach/router";

import { englishToSpanish, spanishToEnglish } from "../../data/url-alternates-data";
import { useLanguageContext } from "../../contexts/language-context";

/**
 * SEO component for setting meta tags, title, and schema data.
 *
 * @param {Object} props
 * @param {Object[]} props.meta - Array of meta tags in the shape { name?: string, property: string, content: string }
 * @param {string} props.title - Title of the page
 * @param {object} props.schema - Schema.org structured data
 * @returns
 */
const SEO = (props) => {
  let propTitle = props.title;
  // TODO: Check if this is unnecessary, we could remove it after a few runs.
  if (typeof props.meta !== "object" && !Array.isArray(props.meta)) {
    throw new Error("SEO.js: Meta data must be an array of objects.");
  }

  const isSpanish = useLanguageContext();
  let path = useLocation().pathname;
  // Remove trailing slash (/) from the URL
  path = decodeURI(path).replace(/\/$/, "");
  const urlMap = isSpanish ? spanishToEnglish : englishToSpanish;
  const urlInOtherLanguage = urlMap[path] || "";
  const defaultOgImage = "https://www.wafdbank.com/images/wafd-logo.png";

  /* Removed the "title + | WaFd Bank" from the project, but some Strapi generated pages still include it.
   * We're now checking if it doesn't have it, then we add it.
   * TODO: Once we migrate to Mongo, we should take out the " | WaFd Bank" from those pages and remove the if here.
   */
  if (!propTitle.match(/ ?\| ?WaFd Bank/i)) {
    propTitle += " | WaFd Bank"; // Error props.title is read only
  } else if (!propTitle.includes(" | WaFd Bank")) {
    // Titles that match but aren't exactly " | WaFd Bank" are logged as warnings.
    // We should look into fixing them, this means they likely have a typo.
    console.warn("SEO.js: Title for page ", path, " matches, but is not ' | WaFd Bank'.");
  }

  // Blogs have headlines, they are the same as title, so we removed it from each blog page and instead we add it here.
  if (props.schema.headline && !props.schema.headline.includes(" | WaFd Bank")) {
    props.schema.headline += " | WaFd Bank";
  }

  let hasOgImage = false;

  const metaData = props.meta.map((metaTag) => {
    if (metaTag.property === "og:image") {
      hasOgImage = true;
      metaTag.content = metaTag.content || defaultOgImage;
    }

    return metaTag;
  });

  if (!hasOgImage) {
    metaData.push({
      property: "og:image",
      content: defaultOgImage
    });
  }

  // We don't have any og:image:width or og:image:height tags in the meta data across the site, so we're just setting it here.
  metaData.push({
    property: "og:image:width",
    content: "250"
  });
  metaData.push({
    property: "og:image:height",
    content: "250"
  });

  const urlData = urlInOtherLanguage
    ? [
        {
          rel: "alternate",
          href: "https://www.wafdbank.com" + path,
          hreflang: isSpanish ? "es-us" : "en-us"
        },
        {
          rel: "alternate",
          href: "https://www.wafdbank.com" + urlInOtherLanguage,
          hreflang: isSpanish ? "en-us" : "es-us"
        }
      ]
    : undefined; // Preventing the render of this property if page has no alternate language version.

  return (
    <Helmet
      htmlAttributes={{ lang: isSpanish ? "es" : "en" }}
      defaultTitle="WaFd Bank"
      title={propTitle}
      link={urlData}
      meta={metaData}
    >
      {Object.keys(props.schema).length !== 0 && (
        <script type="application/ld+json">{JSON.stringify(props.schema)}</script>
      )}
    </Helmet>
  );
};

SEO.defaultProps = {
  meta: [],
  title: "WaFd Bank",
  schema: {}
};

SEO.propTypes = {
  meta: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      property: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired
    })
  ),
  title: PropTypes.string.isRequired,
  schema: PropTypes.object
};

export default SEO;
