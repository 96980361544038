import React from "react";
import { Link } from "gatsby";

// Images
import CookieControlIcon from "../../assets/cookie-control-icon.svg";

const FooterLegal = (props) => (
  <>
    {props.isSpanish ? (
      <div id="footer-legal-spanish" className="col-md-9 col-xl-10">
        <p className="text-muted text-sm mb-3">
          WaFd Bank es un DBA (Doing Business As) del Washington Federal Bank, Asociación Nacional.
        </p>
        <p className="text-muted text-sm mb-3">
          &copy; {new Date().getFullYear()}, WaFd Bank, Todos los derechos reservados. Compañía NMLSR #410394
          <br className="d-inline d-lg-none" />
          <Link id="bfl-terms-of-use" to="/terms-of-use" className="ml-lg-3 text-underline">
            Términos de Uso
          </Link>
          &nbsp;&nbsp;|&nbsp;&nbsp;
          <Link id="bfl-privacy-security" to="/privacy-center" className="text-underline">
            Privacidad y Seguridad
          </Link>
        </p>
      </div>
    ) : (
      <div id="footer-legal" className="col-md-9 col-xl-10">
        <p className="text-muted text-sm mb-3">WaFd Bank is a DBA (Doing Business As) of Washington Federal Bank.</p>
        <p className="text-muted text-sm mb-3">
          &copy; {new Date().getFullYear()}, WaFd Bank, All Rights Reserved. NMLSR Company #410394
        </p>
        <p className="text-sm">
          <Link id="bfl-accessibility" to="/accessibility" className="text-underline">
            Accessibility
          </Link>
          &nbsp;&nbsp;|&nbsp;&nbsp;
          <Link id="bfl-terms-of-use" to="/terms-of-use" className="text-underline">
            Terms of Use
          </Link>
          &nbsp;&nbsp;|&nbsp;&nbsp;
          <Link id="bfl-ccpa-notice" to="/privacy-center#ccpa-notice" className="text-underline">
            Notice of Data Collection
          </Link>
          &nbsp;&nbsp;|&nbsp;&nbsp;
          <Link id="bfl-privacy-security" to="/privacy-center" className="text-underline">
            Privacy Center
          </Link>
          &nbsp;&nbsp;|&nbsp;&nbsp;
          <Link id="bfl-cookie-control" to="/privacy-center#cookie-control" className="text-underline">
            Cookie Control
            <img src={CookieControlIcon} className="ml-1" alt="" style={{ width: "31px", height: "14px" }} />
          </Link>
        </p>
      </div>
    )}
  </>
);

export default FooterLegal;
